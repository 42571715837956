.ant-input-affix-wrapper {
  height: 55px !important;
}

.ant-input {
  font-size: 15px !important;
}

.ant-table-tbody {
  background-color: #f5f5f5 !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 2px solid #afd1d4 !important;
}

.ant-table-cell {
  background-color: #f5f5f5 !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 20px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 20px !important;
}

.ant-table-cell {
  font-size: 22px !important;
}
