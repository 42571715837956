.newControl .css-1s2u09g-control {
  background: #5bbbbd !important;
  border: none !important;
  color: white !important;
  border-radius: 15px;
}
.ad-modal {
  width: 700px !important;
}

.url-input .ant-input {
  background-color: #5bbbbd !important;
  color: white !important;
}

.url-input .ant-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
}
