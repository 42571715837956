.ant-tabs-tab {
  border-radius: 5px !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #e06030;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.ant-tabs-nav {
  display: flex !important;
  width: 100% !important;
}

.ant-tabs-tab {
  flex: 1;
  text-align: center;
}

@media (min-width: 576px) {
  .dashboardStyles {
    flex-basis: 20%;
    width: 20%;
  }
}

@media (min-width: 768px) {
  .dashboardStyles {
    flex-basis: 20%;
    width: 20%;
  }
}

@media (min-width: 992px) {
  .dashboardStyles {
    flex-basis: 20%;
    width: 20%;
  }
}
